import TurndownService from 'turndown';
import ExcelJS from 'exceljs';

const turndownService = new TurndownService()

const getSplitElement = (owner, level) => {
    if (!owner) return ''
    const splitList = owner.split(':')
    if (splitList.length <= level) return ''
    return splitList[level];
}

function convertHtmlToMarkdown(html) {
    if (!html) {
        return null;
    }
    if (!(typeof html === 'string')) {
        return html;
    }
    if (!html.includes('</')) {
        return html
    }

    console.log("==>>MARKDOWN:", typeof turndownService.turndown(html), turndownService.turndown(html))
    return turndownService.turndown(html);
}

const getRichTextData = (searchStr, text) => {
    text = text.replace("****", '')
    console.log("==>>TEXT for BOLD", text)
    let searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    let startIndex = 0, index, boldIndices = [];
    while ((index = text.indexOf(searchStr, startIndex)) > -1) {
        boldIndices.push(index);
        startIndex = index + searchStrLen;
    }
    console.log("==>>BOLD_INDICES", boldIndices)
    let richTextData = []
    if (boldIndices[0] != 0 && boldIndices[boldIndices.length - 1] != text.length - 2) {
        richTextData.push(
            {
                'font': { 'bold': false }, 'text': text.substring(0, boldIndices[0] - 1)
            })
        for (let i = 0; i < boldIndices.length; i = i + 2) {
            console.log("==>C1")
            richTextData.push(
                {
                    'font': { 'bold': true }, 'text': ' ' + text.substring(boldIndices[i] + 2, boldIndices[i + 1]) + ' '
                })
            if (i < boldIndices.length - 2) {
                richTextData.push(
                    {
                        'font': { 'bold': false }, 'text': text.substring(boldIndices[i + 1] + 3, boldIndices[i + 2] - 1)
                    })
            }
        }
        richTextData.push(
            {
                'font': { 'bold': false }, 'text': text.substring(boldIndices[boldIndices.length - 1] + 3, text.length)
            })
        return richTextData
    } else if (boldIndices[0] === 0 && boldIndices[boldIndices.length - 1] != text.length - 2) {
        for (let i = 0; i < boldIndices.length; i = i + 2) {
            console.log("==>C2")
            richTextData.push(
                {
                    'font': { 'bold': true }, 'text': text.substring(boldIndices[i] + 2, boldIndices[i + 1]) + ' '
                })
            if (i < boldIndices.length - 2) {
                richTextData.push(
                    {
                        'font': { 'bold': false }, 'text': text.substring(boldIndices[i + 1] + 3, boldIndices[i + 2] - 1) + ' '

                        //                        'font': { 'bold': false }, 'text': text.substring(boldIndices[i + 1] + 1, boldIndices[i + 2] - 2)
                    })
            }
        }
        richTextData.push(
            {
                'font': { 'bold': false }, 'text': text.substring(boldIndices[boldIndices.length - 1] + 3, text.length)
            })
        return richTextData
    } else if (boldIndices[0] != 0 && boldIndices[boldIndices.length - 1] === text.length - 2) {
        console.log("==>C3")
        richTextData.push(
            {
                'font': { 'bold': false }, 'text': text.substring(0, boldIndices[0])
            })
        for (let i = 0; i < boldIndices.length; i = i + 2) {
            richTextData.push(
                {
                    'font': { 'bold': true }, 'text': ' ' + text.substring(boldIndices[i] + 2, boldIndices[i + 1]) + ' '
                })
            if (i + 1 < boldIndices.length - 2) {
                richTextData.push(
                    {
                        'font': { 'bold': false }, 'text': text.substring(boldIndices[i + 1] + 3, boldIndices[i + 2] - 1)
                    })
            }
        }
        return richTextData
    } else if (boldIndices[0] === 0 && boldIndices[boldIndices.length - 1] === text.length - 2) {
        console.log("==>C4")
        for (let i = 0; i < boldIndices.length; i = i + 2) {
            richTextData.push(
                {
                    'font': { 'bold': true }, 'text': text.substring(boldIndices[i] + 2, boldIndices[i + 1]) + ' '
                })
            if (i < boldIndices.length - 2) {
                richTextData.push(
                    {
                        'font': { 'bold': false }, 'text': text.substring(boldIndices[i + 1] + 3, boldIndices[i + 2] - 1) + ' '
                    })
            }
        }
        return richTextData
    }

}

const exportToExcel = (input, projectName) => {
    let data = input
    let typeOfData = ''
    data[0].ID != undefined ? typeOfData = 'List' : typeOfData = 'History'
    if (data.length > 0 && (Object.keys(data[0]).length !== 0)) {
        if (typeOfData === 'List') {

            let sheetName = "Project_List_Export.xlsx";
            let headerName = "RequestsList";
            let workbook = new ExcelJS.Workbook();
            let sheet = workbook.addWorksheet(sheetName, {
                views: [{ showGridLines: false }]
            });
            // let sheet2 = workbook.addWorksheet("Second sheet", { views: [{ showGridLines: false }] });

            let columnArr = [];
            for (let i in data[0]) {
                let tempObj = { name: "" };
                tempObj.name = i;
                columnArr.push(tempObj);
            }

            sheet.addTable({
                name: `Header`,
                ref: "A1",
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: "",
                    showRowStripes: false,
                    showFirstColumn: true,
                    width: 200
                },
                columns: [{ name: "VIS Project Center" }],
                rows: [[`As of: ${Date()}`], [`Project Export`]]
            });

            sheet.addTable({
                name: headerName,
                ref: "A5",
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: "TableStyleMedium2",
                    showRowStripes: false,
                    width: 200
                },
                columns: columnArr ? columnArr : [{ name: "" }, { filterButton: true }],
                rows: data.map((e) => {
                    let arr = [];
                    for (let i in e) {
                        arr.push(e[i]);
                    }
                    return arr;
                })
            });

            sheet.getCell("A1").font = { size: 20, bold: true };
            sheet.columns = sheet.columns.map((e) => {
                const expr = e.values[21];
                switch (expr) {
                    case "ID":
                        return { width: 50 };
                    case "Start Date":
                        return { numFmt: 'dd/mm/yyyy' };
                    case "Name":
                        return { width: 80 };
                    case "Phase":
                        return { width: 30 };
                    case "Progress Description":
                        return { width: 50 };
                    case "Next Steps":
                        return { width: 50 };
                    case "Risk Evaluation":
                        return { width: 50 };
                    case "Progress":
                        return { width: 50 };
                    case "Cost":
                        return { width: 50 };
                    case "Risk":
                        return { width: 50 };
                    case "Budget":
                        return { width: 50 };
                    case "Applications":
                        return { width: 50 };
                    case "Project Manager":
                        return { width: 50 };
                    case "Lead Inventor":
                        return { width: 50 };
                    case "Owner":
                        return { width: 50 };
                    case "Faculty":
                        return { width: 50 };
                    case "Institute":
                        return { width: 50 };
                    case "Owner 2":
                        return { width: 50 };
                    case "Faculty 2":
                        return { width: 50 };
                    case "Team Members":
                        return { width: 50 };

                    case "Exit Route":
                        return { width: 50 };

                    case "Last Updated":
                        return { width: 50 };

                    case "Institute 2":
                        return { width: 50 };

                    case "Information":
                        return { width: 120 };

                    case "Archive Status":
                        return { width: 50 };

                    default:
                        return { width: 20 };
                }
            });

            const table = sheet.getTable(headerName);
            for (let i = 0; i < table.table.columns.length; i++) {
                sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
                sheet.getCell(`${String.fromCharCode(65 + i)}5`).alignment = {
                    vertical: 'top', horizontal: 'left'
                };
                sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "c5d9f1" }
                };

                let findIDCell = `${String.fromCharCode(65 + i)}${5}`
                if (sheet.getCell(findIDCell).value === "ID") {
                    findIDCell = findIDCell[0]
                }

                let findStartDateCell = `${String.fromCharCode(65 + i)}${5}`
                if (sheet.getCell(findStartDateCell).value === "Start Date") {
                    findStartDateCell = findStartDateCell[0]
                }

                let findPhaseCell = `${String.fromCharCode(65 + i)}${5}`
                if (sheet.getCell(findPhaseCell).value === "Phase") {
                    findPhaseCell = findPhaseCell[0]
                }

                let findLastUpdatedCell = `${String.fromCharCode(65 + i)}${5}`
                if (sheet.getCell(findLastUpdatedCell).value === "Last Updated") {
                    findLastUpdatedCell = findLastUpdatedCell[0]
                }

                for (let j = 0; j < table.table.rows.length; j++) {
                    let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
                    rowCell.value = convertHtmlToMarkdown(rowCell.value)

                    if ((typeof rowCell.value === "string")) {
                        if (rowCell.value.search(/[*][*]/g) != -1) {
                            rowCell.value = {
                                'richText': getRichTextData("**", rowCell.value)
                            }
                        }
                    }

                    rowCell.alignment = { vertical: 'top', horizontal: 'left' };
                    rowCell.border = {
                        bottom: {
                            style: "thin",
                            color: { argb: "a6a6a6" }
                        }
                    };
                    let rowCell1 = sheet.getCell(`${findIDCell}${j + 6}`);
                    rowCell1.style = { numFmt: '0' }
                    rowCell1.alignment = { vertical: 'top', horizontal: 'left' };
                    rowCell1.border = {
                        bottom: {
                            style: "thin",
                            color: { argb: "a6a6a6" }
                        }
                    };
                    let rowCell2 = sheet.getCell(`${findStartDateCell}${j + 6}`);
                    rowCell2.style = { numFmt: 'dd/mm/yyyy' }
                    rowCell2.alignment = { vertical: 'top', horizontal: 'left' };
                    rowCell2.border = {
                        bottom: {
                            style: "thin",
                            color: { argb: "a6a6a6" }
                        }
                    };
                    let rowCell3 = sheet.getCell(`${findPhaseCell}${j + 6}`);
                    rowCell3.style = { numFmt: '0' }
                    rowCell3.alignment = { vertical: 'top', horizontal: 'left' };
                    rowCell3.border = {
                        bottom: {
                            style: "thin",
                            color: { argb: "a6a6a6" }
                        }
                    };
                    let rowCell4 = sheet.getCell(`${findLastUpdatedCell}${j + 6}`);
                    rowCell4.style = { numFmt: 'dd/mm/yyyy' }
                    rowCell4.alignment = { vertical: 'top', horizontal: 'left' };
                    rowCell4.border = {
                        bottom: {
                            style: "thin",
                            color: { argb: "a6a6a6" }
                        }
                    };
                }
            }
            table.commit();

            const writeFile = (fileName, content) => {
                const link = document.createElement('a');
                const blob = new Blob([content], {
                    type: 'application/vnd.ms-excel;charset=utf-8;'
                });
                link.download = fileName;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.href = URL.createObjectURL(blob);
                link.click();
            };

            workbook.xlsx.writeBuffer().then((buffer) => {
                writeFile(sheetName, buffer);
            });
        } else {
            const addToTable = (sheetType, sheetTypeName, dataType, colunmArrType, headerName) => {
                sheetType.addTable({
                    name: headerName,
                    ref: 'A1',
                    headerRow: true,
                    totalsRow: false,
                    style: {
                        theme: ' ',
                        showRowStripes: false,
                        showFirstColumn: true,
                        width: 200
                    },
                    columns: [{ name: 'VIS Project Center' }],
                    rows: [[`As of: ${Date()}`], [projectName], [sheetTypeName]]
                });
                sheetType.addTable({
                    name: headerName,
                    ref: 'A5',
                    headerRow: true,
                    totalsRow: false,
                    style: {
                        theme: 'TableStyleMedium2',
                        showRowStripes: false,
                        width: 200
                    },
                    columns: colunmArrType ? colunmArrType : [{ name: '' }],
                    rows: dataType.map((e) => {
                        let arr = [];
                        for (let i in e) {
                            arr.push(e[i]);
                        }
                        return arr;
                    })
                });
            }
            const formatTable = (tableType, sheetType) => {
                for (let i = 0; i < tableType.table.columns.length; i++) {
                    sheetType.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
                    sheetType.getCell(`${String.fromCharCode(65 + i)}5`).alignment = {
                        vertical: 'top', horizontal: 'left'
                    };
                    sheetType.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'c5d9f1' }
                    };
                    let findChangedOnCell = `${String.fromCharCode(65 + i)}${5}`
                    if (sheetType.getCell(findChangedOnCell).value === "Changed on") {
                        findChangedOnCell = findChangedOnCell[0]
                    }
                    let findCreatedOnCell = `${String.fromCharCode(65 + i)}${5}`
                    if (sheetType.getCell(findCreatedOnCell).value === "Created on") {
                        findCreatedOnCell = findCreatedOnCell[0]
                    }
                    let findStageCell = `${String.fromCharCode(65 + i)}${5}`
                    if (sheetType.getCell(findStageCell).value === "Stage") {
                        findStageCell = findStageCell[0]
                    }
                    for (let j = 0; j < tableType.table.rows.length; j++) {
                        let rowCell = sheetType.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
                        rowCell.value = convertHtmlToMarkdown(rowCell.value)

                        if ((typeof rowCell.value === "string")) {
                            if (rowCell.value.search(/[*][*]/g) != -1) {
                                rowCell.value = {
                                    'richText': getRichTextData("**", rowCell.value)
                                }
                            }
                        }

                        rowCell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
                        rowCell.border = {
                            bottom: {
                                style: 'thin',
                                color: { argb: 'a6a6a6' }
                            }
                        };
                        let rowCell1 = sheetType.getCell(`${findStageCell}${j + 6}`);
                        rowCell1.style = { numFmt: '0' }
                        rowCell1.alignment = { vertical: 'top', horizontal: 'left' };
                        rowCell1.border = {
                            bottom: {
                                style: "thin",
                                color: { argb: "a6a6a6" }
                            }
                        };
                        let rowCell2 = sheetType.getCell(`${findChangedOnCell}${j + 6}`);
                        rowCell2.style = { numFmt: 'dd/mm/yyyy' }
                        rowCell2.alignment = { vertical: 'top', horizontal: 'left' };
                        rowCell2.border = {
                            bottom: {
                                style: "thin",
                                color: { argb: "a6a6a6" }
                            }
                        };
                        let rowCell3 = sheetType.getCell(`${findCreatedOnCell}${j + 6}`);
                        rowCell3.style = { numFmt: 'dd/mm/yyyy' }
                        rowCell3.alignment = { vertical: 'top', horizontal: 'left' };
                        rowCell3.border = {
                            bottom: {
                                style: "thin",
                                color: { argb: "a6a6a6" }
                            }
                        };
                    }
                }
            }
            const sheetColumnsSizeMapping = (sheetType) => {
                sheetType.columns = sheetType.columns.map((e) => {
                    const expr = e.values[19];
                    switch (expr) {
                        default:
                            return { width: 40 };
                    }
                });
            }
            const fillArrayWithHeaderData = (dataType, colunmArr) => {
                for (let i in dataType[0]) {
                    let tempObj = { name: '' };
                    tempObj.name = i;
                    colunmArr.push(tempObj);
                }
            }
            const writeFile = (fileName, content) => {
                const link = document.createElement('a');
                const blob = new Blob([content], {
                    type: 'application/vnd.ms-excel;charset=utf-8;'
                });
                link.download = fileName;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.href = URL.createObjectURL(blob);
                link.click();
            };

            let logData = []
            let statusData = []
            let stageData = []
            let archiveData = []

            let logCount = 0
            let statusCount = 0
            let stageCount = 0
            let archiveCount = 0

            input.forEach(e => {
                if (e.outputType === 'changes') {
                    logCount++
                }
                if (e.outputType === 'status') {
                    statusCount++
                }
                if (e.outputType === 'gate') {
                    stageCount++
                }
                if (e.outputType === 'archive') {
                    archiveCount++
                }
            })

            input.forEach(element => {
                if (element.outputType === 'changes') {
                    let tmpLogObj = {}
                    tmpLogObj['Changed on'] = element.outputData['Log - Changed on'];
                    tmpLogObj['Changed by'] = element.outputData['Log - Changed by'];
                    tmpLogObj['Changed'] = element.outputData['Log - Changed'];
                    logData.push(tmpLogObj)
                }
                if (element.outputType === 'status') {
                    let tmpStatusObj = {}
                    tmpStatusObj['Created on'] = element.outputData['Status - Created on']
                    tmpStatusObj['Created by'] = element.outputData['Status - Created by']
                    tmpStatusObj['Progress'] = element.outputData['Status - Progress']
                    tmpStatusObj['Cost'] = element.outputData['Status - Cost']
                    tmpStatusObj['Risk'] = element.outputData['Status - Risk']
                    tmpStatusObj['Budget'] = element.outputData['Status - Budget']
                    tmpStatusObj['Applications'] = element.outputData['Status - Applications']
                    tmpStatusObj['Progress Description'] = element.outputData['Status - Progress Description']
                    tmpStatusObj['Next Steps'] = element.outputData['Status - Next Steps']
                    tmpStatusObj['Risk Evaluation'] = element.outputData['Status - Risk Evaluation']
                    tmpStatusObj['Archive Status'] = element.outputData['Status - Archive']
                    statusData.push(tmpStatusObj)
                }
                if (element.outputType === 'gate') {
                    let tmpStageObj = {}
                    tmpStageObj['Created on'] = element.outputData['Stage - Created on']
                    tmpStageObj['Created by'] = element.outputData['Stage - Created by']
                    tmpStageObj['Stage'] = element.outputData['Stage - Stage']
                    tmpStageObj['Decision'] = element.outputData['Stage - Decision']
                    tmpStageObj['Checklist'] = element.outputData['Stage - Checklist']
                    stageData.push(tmpStageObj)
                }
                if (element.outputType === 'archive') {
                    let tmpArchiveObj = {}
                    tmpArchiveObj['Created on'] = element.outputData['Archive - Created on']
                    tmpArchiveObj['Created by'] = element.outputData['Archive - Created by']
                    tmpArchiveObj['Status'] = element.outputData['Archive - Archive Status']
                    tmpArchiveObj['Comment'] = element.outputData['Archive - Archive Comment']
                    archiveData.push(tmpArchiveObj)
                }
            });

            const workbookName = 'ProjectHistoryExport.xlsx'
            const headerNameLog = 'Log'
            const headerNameStage = 'Stage'
            const headerNameStatus = 'Status'
            const headerNameArchive = 'Archive'

            const workbook = new ExcelJS.Workbook();

            if (logCount > 0) {
                const sheetLogName = 'Changes';
                let sheetLog = workbook.addWorksheet(sheetLogName, {
                    views: [{ showGridLines: false }]
                });
                let columnLogArr = [];
                fillArrayWithHeaderData(logData, columnLogArr)
                addToTable(sheetLog, sheetLogName, logData, columnLogArr, headerNameLog)
                sheetLog.getCell('A1').font = { size: 20, bold: true };
                sheetColumnsSizeMapping(sheetLog)
                const tableLog = sheetLog.getTable(headerNameLog);
                formatTable(tableLog, sheetLog)
                tableLog.commit();
            }
            if (statusCount > 0) {
                const sheetStatusName = 'Status';
                let sheetStatus = workbook.addWorksheet(sheetStatusName, {
                    views: [{ showGridLines: false }]
                });
                let columnStatusArr = [];
                fillArrayWithHeaderData(statusData, columnStatusArr)
                addToTable(sheetStatus, sheetStatusName, statusData, columnStatusArr, headerNameStatus)
                sheetStatus.getCell('A1').font = { size: 20, bold: true };
                sheetColumnsSizeMapping(sheetStatus)
                const tableStatus = sheetStatus.getTable(headerNameStatus);
                formatTable(tableStatus, sheetStatus)
                tableStatus.commit();
            }
            if (stageCount > 0) {
                const sheetStageName = 'Stage';
                let sheetStage = workbook.addWorksheet(sheetStageName, {
                    views: [{ showGridLines: false }]
                });
                let columnStageArr = [];
                fillArrayWithHeaderData(stageData, columnStageArr)
                addToTable(sheetStage, sheetStageName, stageData, columnStageArr, headerNameStage)
                sheetStage.getCell('A1').font = { size: 20, bold: true };
                sheetColumnsSizeMapping(sheetStage)
                const tableStage = sheetStage.getTable(headerNameStage);
                formatTable(tableStage, sheetStage)
                tableStage.commit();
            }
            if (archiveCount > 0) {
                const sheetArchiveName = 'Archive';
                let sheetArchive = workbook.addWorksheet(sheetArchiveName, {
                    views: [{ showGridLines: false }]
                });
                let columnArchiveArr = [];
                fillArrayWithHeaderData(archiveData, columnArchiveArr)
                addToTable(sheetArchive, sheetArchiveName, archiveData, columnArchiveArr, headerNameArchive)
                sheetArchive.getCell('A1').font = { size: 20, bold: true };
                sheetColumnsSizeMapping(sheetArchive)
                const tableArchive = sheetArchive.getTable(headerNameArchive);
                formatTable(tableArchive, sheetArchive)
                tableArchive.commit();
            }
            workbook.xlsx.writeBuffer().then((buffer) => {
                writeFile(workbookName, buffer);
            });
        }
    } else {
        alert('Nothing exported. Select at least a colunm and project!')
    }
};


export { getSplitElement, exportToExcel }