import React, { useState } from "react";
import { Button, Modal, Icon, Grid, Header, Checkbox, List } from "semantic-ui-react";


const SettingsModalForTable = (props) => {
    const defaultCheckedValues = [true, true, true, true, false, false, false, false, true, true, true, true, false, false, false, false, false, false, false, false, false, false]
    const listOfColumns = props.tableColumns;
        const [open, setOpen] = useState(false)
    const [checkedItem, setCheckedState] = useState(defaultCheckedValues)

    const handleOnChange = (position) => {
        const updateCheckedItem = checkedItem.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updateCheckedItem);
    };
    let arrayOfCheckedItems = []
    const arrayOfCheckedItemsKeys = (checkedItems) => {
        const indicesOfSelectedValues = checkedItems.flatMap((bool, index) => bool ? index : [])
        for (let i = 0; i < indicesOfSelectedValues.length; i++) {
            //arrayOfCheckedItems.push(listOfColumns[indicesOfSelectedValues[i]]["key"])
            arrayOfCheckedItems.push(listOfColumns[indicesOfSelectedValues[i]])
        }
        props.updateTable(arrayOfCheckedItems)
        props.handleArrayOfCheckedItemsForExcel(arrayOfCheckedItems)
        return arrayOfCheckedItems
    }

    const handleSubmitButton = () => {
        arrayOfCheckedItemsKeys(checkedItem)
        setOpen(false)
    }

    const handleClearButton = () => {
        setCheckedState(new Array(listOfColumns.length).fill(false))
    }

    const handleRestoreDefaultButton = () => {
        setCheckedState(defaultCheckedValues)
    }

    const handleSelectAllButton = () => {
        setCheckedState(new Array(listOfColumns.length).fill(true))
    }

    const openModal = () => {
        // handleRestoreDefaultButton()
        setOpen(true)
    }


    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => openModal()}
            open={open}
            closeOnDimmerClick={false}
            closeIcon
            //  dimmer='inverted'
            size="small"
            trigger={<Button ><Icon name={'cog'} /></Button>}
        >
            <Header icon="cog" content="Select columns to be displayed" />
            <Modal.Content>
                <Grid>
                    <ul>
                        {listOfColumns.map(({ text }, index) => {
                            return (
                                <div key={`criteria-no-${index}`}>
                                    <List key={`criteria-no-${index}`}>
                                        <Checkbox
                                            label={text}
                                            id={index}
                                            value={text}
                                            checked={checkedItem[index]}
                                            onChange={() => handleOnChange(index)}
                                        />
                                    </List>
                                    <p></p>
                                </div>
                            );
                        })}
                    </ul>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleSelectAllButton()}>
                    <Icon name="check square" />
                    Select All
                </Button>
                <Button onClick={() => handleClearButton()}>
                    <Icon name="delete" />
                    Clear All
                </Button>
                <Button onClick={() => handleRestoreDefaultButton()}>
                    <Icon name="undo" />
                    Restore Default
                </Button>
                <Button onClick={() => handleSubmitButton()}>
                    <Icon name="check" />
                    Set
                </Button>
            </Modal.Actions>
        </Modal>
    )

};

export default SettingsModalForTable;