import React, { useEffect, useReducer, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import ProjectCardSingle from "./ProjectCardSingle.js";
import FilterPanel from "./filter/FilterPanel.js";
import {
    Button,
    Container,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Table,
} from "semantic-ui-react";
import NewProjectButton from "./new/NewProjectButton";
import ProjectCardTableView from "./ProjectCardTableView.js";
import { projectListReducer } from "../functions/project-card-functions";
import ProgressModalBox from "./modals/ProgressModalBox.js";
import ProjectDetailModalBox from "./modals/ProjectDetailModalBox";
import NewProjectModal from "./new/NewProjectModal";
import ReloadButton from "./ReloadButton.js";
import ArchiveModalBox from "./modals/ArchiveModalBox.js";
import HistoryModal from "./modals/HistoryModal.js";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import { callApiWithToken } from "../functions/fetch";
import { InteractionRequiredAuthError } from "@azure/msal-node";
import TeamMemberAndProjectManagerModalBox from "./modals/TeamMemberAndProjectManagerModalBox";
import CardPlaceholder from "./CardPlaceholder";
import ExportProject from "./ExportProject.js";
import UseSortableData from "../functions/UseSortableData.js";
import "./ProjectCardTableView.css";
import SettingsModalForTable from "./modals/SettingsModalForTable.js";
import TTOProcessModal from "./modals/TTOProcessModal.js";
import BudgetModal from "../budget/BudgetModal.jsx";
import ApplicationModal from "../applications/ApplicationModal.jsx";

const ProjectCardList = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [graphData, setGraphData] = useState(null);
    const userInfo = {
        userId: account?.localAccountId,
        userDetail: account?.username,
        userName: account?.name,
    };
    const [showList, setShowList] = useState(false);

    const [showId, setShowId] = useState(true);
    const [showName, setShowName] = useState(true);
    const [showPhase, setShowPhase] = useState(true);
    const [showProgress, setShowProgress] = useState(false);
    const [showNextSteps, setShowNextSteps] = useState(false);
    const [showRiskEvaluation, setShowRiskEvaluation] = useState(false);
    const [showTrafficLights, setShowTrafficLights] = useState(false);
    const [showProjectManager, setShowProjectManager] = useState(true);
    const [showLeadInventor, setShowLeadInventor] = useState(true);
    const [showOwner1, setShowOwner1] = useState(true);
    const [showOwner2, setShowOwner2] = useState(true);
    const [showLinks, setShowLinks] = useState(false);
    const [showStartDate, setShowStartDate] = useState(true);
    const [showTeamMembers, setShowTeamMembers] = useState(false);
    const [showExitRoute, setShowExitRoute] = useState(false);
    const [showLastUpdated, setShowLastUpdated] = useState(false);
    const [showInformation, setShowInformation] = useState(false);
    const [showDofiDate, setShowDofiDate] = useState(false);
    const [showDofiURL, setShowDofiURL] = useState(false);
    const [showSpinOffDate, setShowSpinOffDate] = useState(false);
    const [showLicenseDate, setShowLicenseDate] = useState(false);
    const [showArchiveStatus, setShowArchiveStatus] = useState(false);

    const [arrayOfCheckedItems, setArrayOfCheckedItems] = useState([]);
    const [state, dispatch] = useReducer(projectListReducer, {
        loading: props.list?.length < 1,
        reload: 0,
        listDataLoaded: props.list,
        listOfValuesDataLoaded: props.listOfValues,
        currentViewList: [],
        modal: "",
        userInfo: userInfo,
        userMessage: "",
        projectKeywordList: [],
        projectManagers: require("../data/ProjectManagerList.json"),
        projectForEdit: {},
        filter: {
            runningFilter: { running: true, onHold: false, archived: false },
            statusApplicationFilter: "",
            projectIdFilter: "",
            textFilter: "",
        },
    });
    const { items, requestSort, sortConfig } = UseSortableData(
        state.currentViewList,
        state.projectManagers,
        props
    );
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handleListView = () => {
        setShowList(true);
    };

    const handleCardView = () => {
        setShowList(false);
    };

    const reloadProjects = async () => {
        dispatch({ type: "projectIdFilter" });
        const { projectList } = await (
            await fetch(
                `/api/ProjectData?function=allProjects&userId=${account.localAccountId}`
            )
        ).json();
        dispatch({ type: "newListFromServer", payload: projectList });
    };

    async function getUserInfo() {
        try {
            const response = await fetch("/.auth/me");
            const payload = await response.json();
            const { clientPrincipal } = payload;
            const userInfo = clientPrincipal;
            dispatch({ type: "userAuthenticated", payload: userInfo });
            return clientPrincipal;
        } catch (error) {
            console.error("No profile could be found");
            return undefined;
        }
    }

    async function getlistOfValues(listName) {
        const { listOfValues } = await (
            await fetch(
                `/api/ProjectData?function=getListOfValues&listName=${listName}&userId=${account.localAccountId}`
            )
        ).json();
        const action = "listOfValues-" + listName;
        dispatch({ type: action, payload: listOfValues });
    }

    async function getProjectManagers() {
        const projectManagers = await (
            await fetch(
                `/api/ProjectData?function=getProjectManagers&userId=${account.localAccountId}`
            )
        ).json();
        const action = "setProjectManagerList";
        dispatch({ type: action, payload: projectManagers });
    }

    useEffect(() => {
        if (account && inProgress === "none" && !graphData) {
            instance
                .acquireTokenSilent({
                    scopes: protectedResources.graphGroup.scopes,
                    account: account,
                })
                .then((response) => {
                    callApiWithToken(
                        response.accessToken,
                        protectedResources.graphGroup.endpoint
                    ).then((response) => setGraphData(response));
                })
                .catch((error) => {
                    // in case if silent token acquisition fails, fallback to an interactive method
                    if (error instanceof InteractionRequiredAuthError) {
                        if (account && inProgress === "none") {
                            instance
                                .acquireTokenPopup({
                                    scopes: protectedResources.graphGroup
                                        .scopes,
                                })
                                .then((response) => {
                                    callApiWithToken(
                                        response.accessToken,
                                        protectedResources.graphGroup.endpoint
                                    ).then((response) =>
                                        setGraphData(response)
                                    );
                                })
                                .catch((error) => console.log(error));
                        }
                    }
                });
        }
    }, [account, inProgress, instance]);

    useEffect(() => {
        if (state.reload) {
            console.log("Reloading project list: " + state.reload);
            (async function () {
                dispatch({ type: "doReload", payload: {} });
            })();
        }
    }, [state.reload]);

    useEffect(() => {
        if (
            graphData &&
            graphData.value &&
            graphData.value[0].displayName === "GatewayPortfolioManagers"
        ) {
            getlistOfValues("projectKeywords");
            getProjectManagers();
            (async function () {
                const { projectList } = await (
                    await fetch(
                        `/api/ProjectData?function=allProjects&userId=${account.localAccountId}`
                    )
                ).json();
                if (projectList.length === 0) {
                    const userMessage =
                        "User does not have access to any projects";
                    dispatch({
                        type: "emptyListFromServer",
                        payload: userMessage,
                    });
                } else {
                    dispatch({
                        type: "newListFromServer",
                        payload: projectList,
                    });
                }
            })();
        }
    }, [graphData]);

    let tableWide = "";
    if (showList) {
        tableWide = "ProjectCardTableViewFull";
    } else {
        tableWide = "ProjectCardTableViewAuto";
    }

    const tableColumns = [
        {
            key: "id",
            text: "ID",
            value: "id",
        },
        {
            key: "startDate",
            text: "Start Date",
            value: "startDate",
        },
        {
            key: "name",
            text: "Name",
            value: "name",
        },
        {
            key: "phase",
            text: "Phase",
            value: "phase",
        },
        {
            key: "progress",
            text: "Progress",
            value: "progress",
        },
        {
            key: "nextSteps",
            text: "Next Steps",
            value: "nextSteps",
        },
        {
            key: "riskEvaluation",
            text: "Risk Evaluation",
            value: "riskEvaluation",
        },
        {
            key: "trafficLights",
            text: "Traffic Lights",
            value: "trafficLights",
        },
        {
            key: "projectManager",
            text: "Project Manager",
            value: "projectManager",
        },
        {
            key: "leadInventor",
            text: "Lead Inventor",
            value: "leadInventor",
        },
        {
            key: "owner",
            text: "Owner",
            value: "owner",
        },
        {
            key: "owner2",
            text: "Owner 2",
            value: "owner2",
        },
        {
            key: "links",
            text: "Links",
            value: "links",
        },
        {
            key: "teamMembers",
            text: "Team Members",
            value: "teamMembers",
        },
        {
            key: "exitRoute",
            text: "Exit Route",
            value: "exitRoute",
        },
        {
            key: "lastUpdated",
            text: "Last Updated",
            value: "lastUpdated",
        },
        {
            key: "information",
            text: "Information",
            value: "information",
        },
        {
            key: "dofiDate",
            text: "Registered DOFI date",
            value: "dofiDate",
        },
        {
            key: "dofiURL",
            text: "DOFI document URL",
            value: "dofiURL",
        },
        {
            key: "spinOffDate",
            text: "Registered Spin-Off Company Date",
            value: "spinOffDate",
        },
        {
            key: "licenseDate",
            text: "Registered License Date",
            value: "licenseDate",
        },
        {
            key: "archiveStatus",
            text: "Archive Status",
            value: "archiveStatus",
        },
    ];

    const updateTable = (value) => {
        if (value.find((e) => e.key === "id")) {
            setShowId(true);
        } else {
            setShowId(false);
        }
        if (value.find((e) => e.key === "name")) {
            setShowName(true);
        } else {
            setShowName(false);
        }

        if (value.find((e) => e.key === "phase")) {
            setShowPhase(true);
        } else {
            setShowPhase(false);
        }

        if (value.find((e) => e.key === "progress")) {
            setShowProgress(true);
        } else {
            setShowProgress(false);
        }

        if (value.find((e) => e.key === "nextSteps")) {
            setShowNextSteps(true);
        } else {
            setShowNextSteps(false);
        }

        if (value.find((e) => e.key === "riskEvaluation")) {
            setShowRiskEvaluation(true);
        } else {
            setShowRiskEvaluation(false);
        }

        if (value.find((e) => e.key === "trafficLights")) {
            setShowTrafficLights(true);
        } else {
            setShowTrafficLights(false);
        }

        if (value.find((e) => e.key === "projectManager")) {
            setShowProjectManager(true);
        } else {
            setShowProjectManager(false);
        }

        setShowOwner1(true);
        if (value.find((e) => e.key === "leadInventor")) {
            setShowLeadInventor(true);
        } else {
            setShowLeadInventor(false);
        }

        if (value.find((e) => e.key === "owner1")) {
        } else {
            setShowOwner1(false);
        }

        if (value.find((e) => e.key === "owner2")) {
            setShowOwner2(true);
        } else {
            setShowOwner2(false);
        }

        if (value.find((e) => e.key === "links")) {
            setShowLinks(true);
        } else {
            setShowLinks(false);
        }

        if (value.find((e) => e.key === "startDate")) {
            setShowStartDate(true);
        } else {
            setShowStartDate(false);
        }

        if (value.find((e) => e.key === "teamMembers")) {
            setShowTeamMembers(true);
        } else {
            setShowTeamMembers(false);
        }

        if (value.find((e) => e.key === "exitRoute")) {
            setShowExitRoute(true);
        } else {
            setShowExitRoute(false);
        }

        if (value.find((e) => e.key === "lastUpdated")) {
            setShowLastUpdated(true);
        } else {
            setShowLastUpdated(false);
        }

        if (value.find((e) => e.key === "information")) {
            setShowInformation(true);
        } else {
            setShowInformation(false);
        }

        if (value.find((e) => e.key === "dofiDate")) {
            setShowDofiDate(true);
        } else {
            setShowDofiDate(false);
        }

        if (value.find((e) => e.key === "dofiURL")) {
            setShowDofiURL(true);
        } else {
            setShowDofiURL(false);
        }

        if (value.find((e) => e.key === "spinOffDate")) {
            setShowSpinOffDate(true);
        } else {
            setShowSpinOffDate(false);
        }

        if (value.find((e) => e.key === "licenseDate")) {
            setShowLicenseDate(true);
        } else {
            setShowLicenseDate(false);
        }

        if (value.find((e) => e.key === "archiveStatus")) {
            setShowArchiveStatus(true);
        } else {
            setShowArchiveStatus(false);
        }
    };

    const handleArrayOfCheckedItemsForExcel = (checkedItems) => {
        setArrayOfCheckedItems(checkedItems);
    };

    const powerBiRapport =
        "https://app.powerbi.com/groups/me/apps/6afc3209-4111-41d9-989c-16af24c58c62/reports/f2c0392f-0b64-4324-bf05-fac66454098d/ReportSectiona95c2a8ee694de0df4cb?experience=power-bi";

    return (
        <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column floated="left" width="4">
                        User {account && account.name}{" "}
                        <p style={{ color: "red" }}>{state.userMessage}</p>
                    </Grid.Column>
                    <Grid.Column floated="right" width="5">
                        <Button
                            circular
                            color="blue"
                            floated="right"
                            onClick={() => {
                                window.open(powerBiRapport, "_blank");
                            }}
                        >
                            <Icon name="external" /> Power BI - VIS
                            Prosjektoversikt
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/* <div>
        <h3>User {account && account.name} <p style={{ color: "red" }}>{state.userMessage}</p> <a target="_blank" href="https://app.powerbi.com/groups/4dccd939-6341-4960-aa9c-4e51e6a56958/reports/f9928058-328c-4441-bed2-b8edd5020348/ReportSection7639e968a9e45ed913da">
          Power BI</a>
        </h3>
      </div>
      <div >
        <Button circular color="blue" onClick={() => { window.open(powerBiRapport, "_blank") }}>
          <Icon name="external" /> Prosjektrapport Power BI
        </Button>
      </div> */}
            <FilterPanel
                projectKeywordList={state.projectKeywordList}
                filterState={state.filter}
                filterDispatch={dispatch}
                projectManagers={state.projectManagers}
            />
            <NewProjectModal
                projectKeywordList={state.projectKeywordList}
                projectManagers={state.projectManagers}
                userInfo={state.userInfo}
                isOpen={state.modal === "newProject"}
                dispatch={dispatch}
                reload={reloadProjects}
            />
            <ProgressModalBox
                project={state.projectForEdit}
                projectKeywordList={state.projectKeywordList}
                userInfo={state.userInfo}
                isOpen={state.modal === "progressModal"}
                dispatch={dispatch}
            />
            <ArchiveModalBox
                project={state.projectForEdit}
                userInfo={state.userInfo}
                isOpen={state.modal === "archiveModal"}
                dispatch={dispatch}
            />
            <ProjectDetailModalBox
                project={state.projectForEdit}
                projectManagers={state.projectManagers}
                projectKeywordList={state.projectKeywordList}
                // createProjectSite={createProjectSite}
                isOpen={state.modal === "projectDetails"}
                dispatch={dispatch}
            />
            {/*<StageGateDecision project={state.projectForEdit} userInfo={state.userInfo} isOpen={state.modal === 'stageGateDecisionModal'} dispatch={dispatch} />*/}
            <TTOProcessModal
                project={state.projectForEdit}
                projectKeywordList={state.projectKeywordList}
                userInfo={state.userInfo}
                isOpen={state.modal === "stageGateDecisionModal"}
                dispatch={dispatch}
            />
            <HistoryModal
                project={state.projectForEdit}
                userInfo={state.userInfo}
                projectManagers={state.projectManagers}
                isOpen={state.modal === "historyModal"}
                dispatch={dispatch}
            />
            <TeamMemberAndProjectManagerModalBox
                project={state.projectForEdit}
                userInfo={state.userInfo}
                projectManagers={state.projectManagers}
                projectKeywordList={state.projectKeywordList}
                isOpen={state.modal === "teamMemberAndProjectManagerModal"}
                dispatch={dispatch}
            />
            <BudgetModal
                project={state.projectForEdit}
                userInfo={state.userInfo}
                isOpen={state.modal === "budgetModal"}
                dispatch={dispatch}
            />
            <ApplicationModal
                project={state.projectForEdit}
                userInfo={state.userInfo}
                isOpen={state.modal === "applicationModal"}
                dispatch={dispatch}
            />

            <Container>
                <Grid doubling columns={2}>
                    <Grid.Column>
                        {" "}
                        <NewProjectButton
                            dispatch={dispatch}
                        ></NewProjectButton>
                        {!showList && (
                            <Button onClick={handleListView}>
                                <Button.Content visible>
                                    <Icon name="list" />
                                </Button.Content>
                            </Button>
                        )}
                        {showList && (
                            <Button onClick={handleCardView}>
                                <Button.Content visible>
                                    <Icon name="id card" />
                                </Button.Content>
                            </Button>
                        )}
                        <ReloadButton reloadProjects={reloadProjects} />
                        <ExportProject
                            currentList={state.currentViewList}
                            dispatch={dispatch}
                            handleCardView={handleCardView}
                            filterDispatch={dispatch}
                            projectManagers={state.projectManagers}
                            arrayOfCheckedItems={arrayOfCheckedItems}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <GridRow style={{ float: "right" }}>
                            <strong>Total projects:</strong>{" "}
                            {state.listDataLoaded.length}
                            <br></br>
                            <strong>Current view:</strong>{" "}
                            {state.currentViewList.length}
                        </GridRow>
                        <GridRow style={{ float: "right" }}>
                            <SettingsModalForTable
                                tableColumns={tableColumns}
                                updateTable={updateTable}
                                handleArrayOfCheckedItemsForExcel={
                                    handleArrayOfCheckedItemsForExcel
                                }
                            />
                        </GridRow>
                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={tableWide}>
                <Table size="small" sortable celled>
                    <Table.Header>
                        <Table.Row>
                            {showId && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("id")}
                                    className={getClassNamesFor("id")}
                                >
                                    ID
                                </Table.HeaderCell>
                            )}
                            {showStartDate && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("startDate")}
                                    className={getClassNamesFor("startDate")}
                                >
                                    Start Date
                                </Table.HeaderCell>
                            )}
                            {showName && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("projectName")}
                                    className={getClassNamesFor("projectName")}
                                >
                                    Name
                                </Table.HeaderCell>
                            )}
                            {showPhase && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("projectGate")}
                                    className={getClassNamesFor("projectGate")}
                                >
                                    Phase
                                </Table.HeaderCell>
                            )}

                            {showProgress && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() =>
                                        requestSort("progressDescription")
                                    }
                                    className={getClassNamesFor(
                                        "progressDescription"
                                    )}
                                >
                                    Progress
                                </Table.HeaderCell>
                            )}

                            {showNextSteps && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("nextSteps")}
                                    className={getClassNamesFor("nextSteps")}
                                >
                                    Next Steps
                                </Table.HeaderCell>
                            )}

                            {showRiskEvaluation && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() =>
                                        requestSort("riskEvaluation")
                                    }
                                    className={getClassNamesFor(
                                        "riskEvaluation"
                                    )}
                                >
                                    Risk Evaluation
                                </Table.HeaderCell>
                            )}

                            {showTrafficLights && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() =>
                                        requestSort("Traffic Lights")
                                    }
                                    className={getClassNamesFor(
                                        "Traffic Lights"
                                    )}
                                >
                                    Traffic Lights
                                </Table.HeaderCell>
                            )}

                            {showProjectManager && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() =>
                                        requestSort("projectManager")
                                    }
                                    className={getClassNamesFor(
                                        "projectManager"
                                    )}
                                >
                                    Project Manager
                                </Table.HeaderCell>
                            )}

                            {showLeadInventor && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("leadInventor")}
                                    className={getClassNamesFor("leadInventor")}
                                >
                                    Lead Inventor
                                </Table.HeaderCell>
                            )}

                            {showOwner1 && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("owningUnit1")}
                                    className={getClassNamesFor("owningUnit1")}
                                >
                                    Owner1
                                </Table.HeaderCell>
                            )}

                            {showOwner2 && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("owningUnit2")}
                                    className={getClassNamesFor("owningUnit2")}
                                >
                                    Owner2
                                </Table.HeaderCell>
                            )}
                            {showLinks && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("projectSite")}
                                    className={getClassNamesFor("projectSite")}
                                >
                                    Links
                                </Table.HeaderCell>
                            )}
                            {showTeamMembers && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("projectTeam")}
                                    className={getClassNamesFor("projectTeam")}
                                >
                                    Team Members
                                </Table.HeaderCell>
                            )}
                            {showExitRoute && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("exitRoute")}
                                    className={getClassNamesFor("exitRoute")}
                                >
                                    Exit Route
                                </Table.HeaderCell>
                            )}
                            {showLastUpdated && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("lastUpdated")}
                                    className={getClassNamesFor("lastUpdated")}
                                >
                                    Last Updated
                                </Table.HeaderCell>
                            )}
                            {showInformation && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() =>
                                        requestSort(
                                            "nonConfidentialInformation"
                                        )
                                    }
                                    className={getClassNamesFor(
                                        "nonConfidentialInformation"
                                    )}
                                >
                                    Information
                                </Table.HeaderCell>
                            )}
                            {showDofiDate && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("dofiDate")}
                                    className={getClassNamesFor("dofiDate")}
                                >
                                    DOFI Date
                                </Table.HeaderCell>
                            )}
                            {showDofiURL && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("dofiURL")}
                                    className={getClassNamesFor("dofiURL")}
                                >
                                    DOFI Document URL
                                </Table.HeaderCell>
                            )}
                            {showSpinOffDate && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("spinOffDate")}
                                    className={getClassNamesFor("spinOffDate")}
                                >
                                    Spin-Off Company Establishment Date
                                </Table.HeaderCell>
                            )}
                            {showLicenseDate && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("licenseDate")}
                                    className={getClassNamesFor("licenseDate")}
                                >
                                    License Agreement Date
                                </Table.HeaderCell>
                            )}
                            {showArchiveStatus && (
                                <Table.HeaderCell
                                    sorted={sortConfig}
                                    onClick={() => requestSort("archiveStatus")}
                                    className={getClassNamesFor("archiveStatus")}
                                >
                                    Archive Status
                                </Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>
                    {showList && (
                        <ProjectCardTableView
                            currentList={items}
                            dispatch={dispatch}
                            handleCardView={handleCardView}
                            filterDispatch={dispatch}
                            projectManagers={state.projectManagers}
                            showId={showId}
                            showName={showName}
                            showPhase={showPhase}
                            showProgress={showProgress}
                            showNextSteps={showNextSteps}
                            showRiskEvaluation={showRiskEvaluation}
                            showTrafficLights={showTrafficLights}
                            showProjectManager={showProjectManager}
                            showLeadInventor={showLeadInventor}
                            showOwner1={showOwner1}
                            showOwner2={showOwner2}
                            showLinks={showLinks}
                            showStartDate={showStartDate}
                            showTeamMembers={showTeamMembers}
                            showExitRoute={showExitRoute}
                            showLastUpdated={showLastUpdated}
                            showInformation={showInformation}
                            showDofiDate={showDofiDate}
                            showDofiURL={showDofiURL}
                            showSpinOffDate={showSpinOffDate}
                            showLicenseDate={showLicenseDate}
                            showArchiveStatus={showArchiveStatus}
                        />
                    )}
                </Table>
            </Container>
            <Divider hidden></Divider>
            {!showList && (
                <Grid stackable columns="2">
                    {state.loading && <CardPlaceholder />}
                    {!state.loading &&
                        items.map((oneProject) => (
                            <Grid.Column key={oneProject.id}>
                                <ProjectCardSingle
                                    project={oneProject}
                                    projectDispatch={dispatch}
                                    projectManagers={state.projectManagers}
                                />
                            </Grid.Column>
                        ))}
                </Grid>
            )}
        </Container>
    );
};

export default ProjectCardList;
