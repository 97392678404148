import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon } from 'semantic-ui-react';
import { lookUpProjectManagerName } from "../functions/project-manager-functions";
import { reformatDateForExcelJS } from '../functions/reformat-date'
import { getLastUpdatedDate } from '../functions/getLastUpdated';
import { getProjectTeam } from '../functions/getProjectTeam';
import { addFieldToProject } from '../functions/project-card-functions';
import { getSplitElement, exportToExcel } from '../functions/export-data-functions';
import { statusBudget } from '../budget/budget-functions';
import { statusApplications } from '../applications/application-functions';

const ExportProject = (props) => {

    const getSelected = (checkList, item) => {
        let output = {}
        for (let x in checkList) {
            if (checkList[x].key === "id") {
                output = addFieldToProject(output, "ID", parseInt(item.id))
            }
            if (checkList[x].key === "name") {
                output = addFieldToProject(output, "Name", item.projectName)
            }
            if (checkList[x].key === "phase") {
                output = addFieldToProject(output, "Phase", parseInt(item.projectGate))
            }
            if (checkList[x].key === "progress") {
                output = addFieldToProject(output, "Progress Description", item.progressDescription)
            }
            if (checkList[x].key === "nextSteps") {
                output = addFieldToProject(output, "Next Steps", item.nextSteps)
            }
            if (checkList[x].key === "riskEvaluation") {
                output = addFieldToProject(output, "Risk Evaluation", item.riskEvaluation)
            }
            if (checkList[x].key === "trafficLights") {
                output = addFieldToProject(output, "Progress", item.statusProgress)
            }
            if (checkList[x].key === "trafficLights") {
                output = addFieldToProject(output, "Cost", item.statusCost)
            }
            if (checkList[x].key === "trafficLights") {
                output = addFieldToProject(output, "Risk", item.statusRisk)
            }
            if (checkList[x].key === "trafficLights") {
                output = addFieldToProject(output, "Budget", statusBudget(item.budget))
            }
            if (checkList[x].key === "trafficLights") {
                output = addFieldToProject(output, "Applications", statusApplications(item.applications))
            }
            if (checkList[x].key === "projectManager") {
                output = addFieldToProject(output, "Project Manager", lookUpProjectManagerName(item.ownerId, props.projectManagers))
            }
            if (checkList[x].key === "leadInventor") {
                output = addFieldToProject(output, "Lead Inventor", item.leadInventor)
            }
            if (checkList[x].key === "owner") {
                output = addFieldToProject(output, "Owner", getSplitElement(item.owningUnit1, 0))
            }
            if (checkList[x].key === "owner") {
                output = addFieldToProject(output, "Faculty", getSplitElement(item.owningUnit1, 1))
            }
            if (checkList[x].key === "owner") {
                output = addFieldToProject(output, "Institute", getSplitElement(item.owningUnit1, 2))
            }
            if (checkList[x].key === "owner2") {
                output = addFieldToProject(output, "Owner 2", getSplitElement(item.owningUnit2, 0))
            }
            if (checkList[x].key === "owner2") {
                output = addFieldToProject(output, "Faculty 2", getSplitElement(item.owningUnit2, 1))
            }
            if (checkList[x].key === "owner2") {
                output = addFieldToProject(output, "Institute 2", getSplitElement(item.owningUnit2, 2))
            }
            if (checkList[x].key === "startDate") {
                output = addFieldToProject(output, "Start Date", reformatDateForExcelJS(item.startDate))
            }
            if (checkList[x].key === "teamMembers") {
                output = addFieldToProject(output, "Team Members", getProjectTeam(item.projectTeam, props.projectManagers))
            }
            if (checkList[x].key === "exitRoute") {
                output = addFieldToProject(output, "Exit Route", item.exitRoute)
            }
            if (checkList[x].key === "lastUpdated") {
                output = addFieldToProject(output, "Last Updated", reformatDateForExcelJS(getLastUpdatedDate(item.history)))
            }
            if (checkList[x].key === "information") {
                output = addFieldToProject(output, "Information", item.nonConfidentialInformation)
            }
            if (checkList[x].key === "dofiDate") {
                output = addFieldToProject(output, "DOFI Date", reformatDateForExcelJS(item.dofiDate))
            }
            if (checkList[x].key === "dofiURL") {
                output = addFieldToProject(output, "DOFI URL", item.dofiURL)
            }
            if (checkList[x].key === "spinOffDate") {
                output = addFieldToProject(output, "Spin-Off Date", reformatDateForExcelJS(item.spinOffDate))
            }
            if (checkList[x].key === "licenseDate") {
                output = addFieldToProject(output, "License Agreement Date", reformatDateForExcelJS(item.licenseDate))
            }
            if (checkList[x].key === "archiveStatus") {
                output = addFieldToProject(output, "Archive Status", item.archiveStatus)
            }
            else {
                console.log("Archive Status not checked")
            }
        }
        return output
    }

    const mapToTable = (items) => {
        let checkList = props.arrayOfCheckedItems
        return items.map((item) => {
            if (checkList === undefined || checkList.length === 0) {
                checkList = [
                    {
                        "key": "id",
                        "text": "ID",
                        "value": "id"
                    },
                    {
                        "key": "startDate",
                        "text": "Start Date",
                        "value": "startDate"
                    },
                    {
                        "key": "name",
                        "text": "Name",
                        "value": "name"
                    },
                    {
                        "key": "phase",
                        "text": "Phase",
                        "value": "phase"
                    },
                    {
                        "key": "projectManager",
                        "text": "Project Manager",
                        "value": "projectManager"
                    },
                    {
                        "key": "leadInventor",
                        "text": "Lead Inventor",
                        "value": "leadInventor"
                    },
                    {
                        "key": "owner",
                        "text": "Owner",
                        "value": "owner"
                    },
                    {
                        "key": "owner2",
                        "text": "Owner 2",
                        "value": "owner2"
                    }
                ]
            }
            return getSelected(checkList, item)
        });
    }

    return (
        <Button
            onClick={() => {
                exportToExcel(mapToTable(props.currentList));
            }}
        >
            <Button.Content visible><Icon name="file excel outline" /></Button.Content>
        </Button>
    )
}

export default ExportProject;